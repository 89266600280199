"use client";

import { PrimaryButton, PrimaryLinkButton } from "movestic-ui/tailwind/Buttons";
import { H1 } from "movestic-ui/tailwind/Texts";

function Error() {
    return (
        <div className="container p-4 md:p-8">
            <H1>Tekniskt fel</H1>
            <div>Det här gick tyvärr inte så bra, testa att ladda om sidan eller försök igen senare.</div>
            <br />
            <PrimaryLinkButton className="inline-flex" href="/">
                Gå till startsidan
            </PrimaryLinkButton>
        </div>
    );
}

export default Error;
